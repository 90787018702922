<template>
  <ul
    class="editor-navigation bg-grey-gradient"
    :class="pValue ? 'editor-navigation-opened' : ''"
    v-click-outside="clickOutsideHandler"
  >
    <li
      v-for="button in pButtons"
      :key="button.name"
      class="direction-wrapper navigator-icon-wrapper"
      @click="(event) => action(button, event)"
    >
      <v-badge
        bordered
        :value="button.used ? true : false"
        top
        color="primary"
        dot
        offset-x="22"
        offset-y="5"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="navigation-icon direction-icon"
              icon
            >
              <v-icon size="18" color="white"
                >{{
                  button.openIcon && button.closeIcon
                    ? pValue
                      ? button.openIcon
                      : button.closeIcon
                    : button.icon
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ button.name }}</span>
        </v-tooltip>
      </v-badge>
    </li>

    <div class="editor-navigation-line"></div>

    <div
      class="editor-container"
      :class="{ 'editor-container-opened': pValue }"
    >
      <slot name="content"> </slot>
    </div>
  </ul>
</template>


<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hideOnClickOutside: {
      type: Boolean,
      default: true,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editionHidden: false,
      pValue: this.value || false,
      pButtons: this.buttons,
    };
  },
  watch: {
    buttons: {
      handler: function (newVal) {
        this.pButtons = newVal;
      },
      deep: true,
    },
    value(newVal) {
      this.pValue = newVal;
    },
  },
  updated() {},
  methods: {
    action(button, $event) {
      if (button.action === "onExpand") {
        return this.pValue ? this.collapse($event) : this.expand($event);
      }
      this.$emit(button.action, $event);
    },
    clickOutsideHandler($event) {
      if ($event) $event.preventDefault();
      if (!this.hideOnClickOutside) return;
      else this.collapse($event);
    },
    collapse($event) {
      if ($event) $event.preventDefault();

      this.pValue = false;

      this.$emit("input", false);
    },
    expand($event) {
      if ($event) $event.preventDefault();
      this.pValue = true;

      this.$emit("input", true);
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .usage-badge .v-badge__badge {
  transform: rotate(-45deg);
  top: -18px !important;
  left: 4px !important;
  height: 20px;
}

.editor-navigation {
  position: fixed;
  top: 20px;
  right: 0px;
  display: inline-block;
  vertical-align: middle;
  width: 0px;
  height: 100%;
  transition: all 0.4s;
  z-index: 8;
  padding-left: 0px !important;

  font-weight: 300;
  font-size: 14px;
  line-height: 36px;

  &.editor-navigation-opened {
    width: 50%;

    @media (max-width: 1264px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      // width: calc(100% - 50px);
      width: 100%;
      padding-left: 50px !important;
    }
  }

  .editor-navigation-line {
    cursor: pointer;
    width: 24px;
    height: 100%;
    // background: linear-gradient(to right, #cd303b, #fc4a03);
    // background-color: white;
  }

  .navigator-icon-wrapper {
    padding: 10px;
    margin-left: -40px;

    margin-top: 14px;
    transition: all 0.4s;
    transform-style: preserve-3d;
    border: 1px solid black;

    width: 32px;
    height: 32px;
    .theme--light.v-icon:focus::after {
      opacity: 0 !important;
    }

    .navigation-icon {
      font-size: 20px;
      font-weight: 300;
    }
    &:hover {
      //    transform: rotateY(20deg);

      // background-color: rgba(0,0,0,0.8);
      border: 1px solid white;
    }
  }

  .navigator-icon-wrapper:first-child {
    background-color: white;
  }

  .navigator-icon-wrapper:nth-child(odd) {
    background: linear-gradient(to right, #010c15, #1f2830);
  }

  .navigator-icon-wrapper:nth-child(even) {
    background: linear-gradient(to right, #cd303b, #fc4a03);
    border: 1px solid #cd303b;
  }

  .navigator-icon-wrapper::after {
    background-color: transparent !important;
  }
  .editor-container {
    max-height: calc(100vh - 200px);
    margin: 10px;

    width: 100%;
    position: absolute;
    padding-left: 20px;

    top: 14px;

    right: -270px;

    transition: all 0.4s;

    .navigation-element-icon {
      font-size: 18px;
      line-height: 36px;
    }
  }
  .editor-container-opened {
    right: 0px;
  }

  .editor-container::-webkit-scrollbar {
    width: 4px;
  }

  .editor-container::-webkit-scrollbar-track {
    background: #616161;
    border-radius: 100px;
  }

  .editor-container::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #bdbdbd;
  }
}
</style>